/**
 * Copyright 2023 SweetCode. All rights reserved.
 *
 * Load Outbrain Ads functions
 * */

(function (wpm, $, undefined) {

	wpm.loadOutbrainPixel = () => {

		const outbrainId = wpmDataLayer.pixels.outbrain.advertiser_id

		try {

			// @formatter:off
			!function(_window, _document) {
				var OB_ADV_ID = outbrainId;
				if (_window.obApi) {
					var toArray = function(object) {
						return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];
					};
					_window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(toArray(OB_ADV_ID));
					return;
				}
				var api = _window.obApi = function() {
					api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);
				};
				api.version = '1.1';
				api.loaded = true;
				api.marketerId = OB_ADV_ID;
				api.queue = [];
				var tag = _document.createElement('script');
				tag.async = true;
				tag.src = '//amplify.outbrain.com/cp/obtp.js';
				tag.type = 'text/javascript';
				var script = _document.getElementsByTagName('script')[0];
				script.parentNode.insertBefore(tag, script);
			}(window, document);

			obApi('track', 'PAGE_VIEW');
			// @formatter:on

			wpmDataLayer.pixels.outbrain.loaded = true

		} catch (e) {
			console.error(e)
		}
	}

}(window.wpm = window.wpm || {}, jQuery));
