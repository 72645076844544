/**
 * Consent Mode functions
 */

(function (wpm, $, undefined) {

	/**
	 * Handle Cookie Management Platforms
	 */

	const getComplianzCookies = () => {

		let cmplz_statistics     = wpm.getCookie("cmplz_statistics")
		let cmplz_marketing      = wpm.getCookie("cmplz_marketing")
		let cmplz_consent_status = wpm.getCookie("cmplz_consent_status") || wpm.getCookie("cmplz_banner-status")

		if (cmplz_consent_status) {
			return {
				analytics       : cmplz_statistics === "allow",
				ads             : cmplz_marketing === "allow",
				visitorHasChosen: true,
			}
		}

		return false
	}

	const getCookieYesCookies = () => {

		/**
		 * Process the new cookieyes-consent cookie that contains all the information
		 */

		let cookie = wpm.getCookie("cookieyes-consent")

		if (cookie) {

			// This cookie has the following structure:
			// consentid:OERmMEJCSVQxRk1SRWVwZkJTUjFBZTJHSEhkdjhLbUI,consent:yes,action:yes,necessary:yes,functional:yes,analytics:yes,performance:yes,advertisement:yes
			// First split the comma-separated values into an array
			// Then split each array item into a key value pair. The key and the value are separated by a colon.
			// Then create an object from the key value pairs.
			// Then console.log the object to see what it looks like.

			cookie = cookie
				.split(",")
				.map(item => item.split(":"))
				.reduce((acc, [key, value]) => ({
					...acc,
					[key]: value,
				}), {})

			// console.log("new cookie", cookie)

			return {
				analytics       : cookie.analytics && cookie.analytics === "yes",
				ads             : cookie.advertisement && cookie.advertisement === "yes",
				visitorHasChosen: cookie.advertisement && cookie.advertisement === "yes",
			}
		}

		/**
		 * Process the old cookieyes-* cookies
		 *
		 * @param cookieNames
		 * @returns {string|string|boolean}
		 */

		function checkCookie(cookieNames) {
			for (let name of cookieNames) {

				const cookieValue = wpm.getCookie(name)

				if (cookieValue) {
					return cookieValue
				}
			}
			return false
		}

		let analyticsCookieNames = [
			"cookielawinfo-checkbox-analytics",
			"cookielawinfo-checkbox-analytiques",
			"cookieyes-analytics",
		]

		const adsCookieNames = [
			"cookielawinfo-checkbox-advertisement",
			"cookielawinfo-checkbox-performance",
			"cookielawinfo-checkbox-publicite",
			"cookieyes-advertisement",
		]

		const visitorHasChosenCookieNames = [
			"CookieLawInfoConsent",
			"cky-action",
		]

		// let analyticsCookie  = wpm.getCookie("cookielawinfo-checkbox-analytics") || wpm.getCookie("cookielawinfo-checkbox-analytiques")
		// let adsCookie        = wpm.getCookie("cookielawinfo-checkbox-advertisement") || wpm.getCookie("cookielawinfo-checkbox-performance") || wpm.getCookie("cookielawinfo-checkbox-publicite")
		// let visitorHasChosen  = wpm.getCookie("CookieLawInfoConsent")

		const analyticsCookie  = checkCookie(analyticsCookieNames)
		const adsCookie        = checkCookie(adsCookieNames)
		const visitorHasChosen = checkCookie(visitorHasChosenCookieNames)

		if (analyticsCookie || adsCookie) {

			return {
				analytics       : analyticsCookie === "yes",
				ads             : adsCookie === "yes",
				visitorHasChosen: !!visitorHasChosen,
			}
		}

		return false
	}

	/**
	 * Get the Iubenda cookie.
	 * Then parse it and return the analytics and marketing values.
	 *
	 * 1 = Necessary
	 * 2 = Functionality
	 * 3 = n/a
	 * 4 = Measurement
	 * 5 = Marketing
	 */
	const getIubendaCookieInfo = () => {

		// Regex that matches _iub_cs-59340121 but not _iub_cs-59340121-granular

		let regex = new RegExp("_iub_cs-\\d{8,}")

		// let cookie = wpm.getCookieThatContainsRegex("_iub_cs-")
		let cookie = wpm.getCookieThatContainsRegex(regex)

		// Parse the cookie into an object
		// The structure looks like this:
		// %7B%22timestamp%22%3A%222023-07-11T06%3A43%3A40.886Z%22%2C%22version%22%3A%221.48.0%22%2C%22purposes%22%3A%7B%221%22%3Atrue%2C%222%22%3Atrue%2C%224%22%3Atrue%2C%225%22%3Atrue%7D%2C%22id%22%3A63374232%7D

		if (cookie) {

			cookie = decodeURIComponent(cookie)

			// Now the structure looks like this:
			// _iub_cs-63374232={"timestamp":"2023-07-11T06:43:40.886Z","version":"1.48.0","purposes":{"1":true,"2":true,"4":true,"5":true},"id":63374232}
			// Remove the _iub_cs- prefix until and with the equal sign. The rest is the object we want to keep and parse.
			cookie = cookie.replace(/_iub_cs-.*=/, "")

			cookie = JSON.parse(cookie)

			return {
				analytics       : cookie.purposes["4"],
				ads             : cookie.purposes["5"],
				visitorHasChosen: true,
			}
		}

		return false
	}

	const getTermlyCookies = () => {

		// Get Termly cookies from localStorage from termly_gtm_template_default_consents for ad_storage and for analytics_storage
		let termlyConsent = localStorage.getItem("termly_gtm_template_default_consents")

		if (termlyConsent) {
			termlyConsent = JSON.parse(termlyConsent)

			return {
				analytics       : termlyConsent.analytics_storage === "granted",
				ads             : termlyConsent.ad_storage === "granted",
				visitorHasChosen: true,
			}
		}

		return false
	}


	/**
	 * Initialize and set default values
	 */

	let
		wpmConsentValues              = {}
	wpmConsentValues.categories       = {}
	wpmConsentValues.pixels           = []
	wpmConsentValues.mode             = "category"
	wpmConsentValues.visitorHasChosen = false

	// Return current consent values
	wpm.getConsentValues = () => wpmConsentValues

	wpm.setConsentValueCategories = (analytics = false, ads = false) => {
		wpmConsentValues.categories.analytics = analytics
		wpmConsentValues.categories.ads       = ads
	}

	// Update the PMW consent values with values coming from a CMP
	wpm.updateConsentCookieValues = (analytics = null, ads = null, explicitConsent = false) => {

		// ad_storage
		// analytics_storage
		// functionality_storage
		// personalization_storage
		// security_storage

		let cookie

		/**
		 * Setup defaults
		 */

		// consentValues.categories.analytics = true
		// consentValues.categories.ads       = true

		wpmConsentValues.categories.analytics = !explicitConsent
		wpmConsentValues.categories.ads       = !explicitConsent


		if (analytics || ads) {

			if (analytics) {
				wpmConsentValues.categories.analytics = !!analytics
			}

			if (ads) {
				wpmConsentValues.categories.ads = !!ads
			}

			return
		}

		/**
		 * PMW Cookie Consent
		 *
		 * Must be processed before every other CMP for the case that one of the included CMPs
		 * decides to implement the PMW cookie consent API. In that case
		 * the PMW consent cookie must take precedence.
		 */

		if (cookie = wpm.getCookie("pmw_cookie_consent")) {

			cookie = JSON.parse(cookie)

			wpmConsentValues.categories.analytics = cookie.analytics === true
			wpmConsentValues.categories.ads       = cookie.ads === true
			wpmConsentValues.visitorHasChosen     = true

			return
		}

		/**
		 * Cookiebot
		 * https://wordpress.org/plugins/cookiebot/
		 */

		if (cookie = wpm.getCookie("CookieConsent")) {

			cookie = decodeURI(cookie)

			wpmConsentValues.categories.analytics = cookie.indexOf("statistics:true") >= 0
			wpmConsentValues.categories.ads       = cookie.indexOf("marketing:true") >= 0
			wpmConsentValues.visitorHasChosen     = true

			return
		}

		/**
		 * WP Consent API
		 * https://github.com/rlankhorst/wp-consent-level-api
		 * https://wordpress.org/plugins/wp-consent-api/
		 */

		// if a at least one cookie exists that starts with wp_consent_ then the visitor has chosen
		if (wpm.getCookieThatContainsRegex(/^wp_consent_/)) {
			// console.log("WP Consent API settings detected")

			// if the cookie wp_consent_statistics exists and is set to allow, then analytics is true
			// If it is set to deny, then analytics is false
			wpmConsentValues.categories.analytics = wpm.getCookie("wp_consent_statistics") === "allow"
			wpmConsentValues.categories.ads       = wpm.getCookie("wp_consent_marketing") === "allow"

			wpmConsentValues.visitorHasChosen = true

			// console log the consent values
			// console.log("WP Consent API settings detected: ", wpmConsentValues)

			return
		}

		/**
		 * Cookie Script
		 * https://wordpress.org/plugins/cookie-script-com/
		 */

		if (cookie = wpm.getCookie("CookieScriptConsent")) {

			cookie = JSON.parse(cookie)

			// if cookie.categories is not an array, but a string, parse it
			if (typeof cookie.categories === "string") cookie.categories = JSON.parse(cookie.categories)

			if (
				cookie.action
				&& cookie.action === "reject"
			) {
				wpmConsentValues.categories.analytics = false
				wpmConsentValues.categories.ads       = false
			} else if (
				cookie.categories
				&& cookie.categories.length > 0
			) {
				wpmConsentValues.categories.analytics = cookie.categories.indexOf("performance") >= 0
				wpmConsentValues.categories.ads       = cookie.categories.indexOf("targeting") >= 0
			} else {
				wpmConsentValues.categories.analytics = true
				wpmConsentValues.categories.ads       = true
			}

			wpmConsentValues.visitorHasChosen = true

			return
		}

		/**
		 * Borlabs Cookie
		 * https://borlabs.io/borlabs-cookie/
		 */
		if (cookie = wpm.getCookie("borlabs-cookie")) {

			cookie = decodeURI(cookie)
			cookie = JSON.parse(cookie)

			wpmConsentValues.categories.analytics = !!cookie?.consents?.statistics
			wpmConsentValues.categories.ads       = !!cookie?.consents?.marketing
			wpmConsentValues.visitorHasChosen     = true
			wpmConsentValues.pixels               = [...cookie?.consents?.statistics || [], ...cookie?.consents?.marketing || []]
			wpmConsentValues.mode                 = "pixel"

			return
		}

		/**
		 * Complianz Cookie
		 * https://wordpress.org/plugins/complianz-gdpr/
		 */

		if (cookie = getComplianzCookies()) {

			wpmConsentValues.categories.analytics = cookie.analytics === true
			wpmConsentValues.categories.ads       = cookie.ads === true
			wpmConsentValues.visitorHasChosen     = cookie.visitorHasChosen

			return
		}

		/**
		 * Cookie Compliance (free version)
		 * https://wordpress.org/plugins/cookie-notice/
		 */

		if (cookie = wpm.getCookie("cookie_notice_accepted")) {

			wpmConsentValues.categories.analytics = true
			wpmConsentValues.categories.ads       = true
			wpmConsentValues.visitorHasChosen     = true

			return
		}

		/**
		 * Cookie Compliance (pro version)
		 * https://wordpress.org/plugins/cookie-notice/
		 */

		if (cookie = wpm.getCookie("hu-consent")) {

			cookie = JSON.parse(cookie)

			wpmConsentValues.categories.analytics = !!cookie.categories["3"]
			wpmConsentValues.categories.ads       = !!cookie.categories["4"]
			wpmConsentValues.visitorHasChosen     = true

			return
		}

		/**
		 * CookieYes, GDPR Cookie Consent (Cookie Law Info)
		 * https://wordpress.org/plugins/cookie-law-info/
		 */

		if (cookie = getCookieYesCookies()) {

			wpmConsentValues.categories.analytics = cookie.analytics === true
			wpmConsentValues.categories.ads       = cookie.ads === true
			wpmConsentValues.visitorHasChosen     = cookie.visitorHasChosen === true

			return
		}

		/**
		 * GDPR Cookie Compliance Plugin by Moove Agency
		 * https://wordpress.org/plugins/gdpr-cookie-compliance/
		 *
		 * TODO write documentation on how to set up the plugin in order for this to work properly
		 */

		if (cookie = wpm.getCookie("moove_gdpr_popup")) {

			cookie = JSON.parse(cookie)

			wpmConsentValues.categories.analytics = cookie.thirdparty === "1"
			wpmConsentValues.categories.ads       = cookie.advanced === "1"
			wpmConsentValues.visitorHasChosen     = true

			return
		}

		/**
		 * Iubenda
		 * https://www.iubenda.com
		 */

		if (cookie = getIubendaCookieInfo()) {

			cookie = cookie

			wpmConsentValues.categories.analytics = cookie.analytics === true
			wpmConsentValues.categories.ads       = cookie.ads === true
			wpmConsentValues.visitorHasChosen     = true

			// console.log("Iubenda settings detected: ", wpmConsentValues)

			return
		}

		/**
		 * WP AutoTerms
		 * https://wordpress.org/plugins/auto-terms-of-service-and-privacy-policy/
		 */

		if (cookie = wpm.getCookie("wpautoterms-cookies-notice")) {

			if (cookie !== "1") return

			wpmConsentValues.categories.analytics = true
			wpmConsentValues.categories.ads       = true
			wpmConsentValues.visitorHasChosen     = true

			return
		}

		/**
		 * Usercentrics
		 *
		 * https://usercentrics.com/
		 * https://docs.usercentrics.com/#/cmp-v2-ui-api
		 * https://docs.usercentrics.com/#/cmp-v2-ui-api?id=getservicesbaseinfo
		 */

		if (window.localStorage && window.localStorage.getItem("uc_settings")) {

			console.log("Usercentrics settings detected")

			if (typeof UC_UI === "undefined") {

				// register event to block unblock after UC_UI library is loaded
				window.addEventListener("UC_UI_INITIALIZED", (event) => {
					wpm.ucUiProcessConsent()
				})

				// Don't continue because in here the UC_UI library is not loaded yet
				return
			}

			if (UC_UI.areAllConsentsAccepted()) {
				wpmConsentValues.categories.analytics = true
				wpmConsentValues.categories.ads       = true
				wpmConsentValues.visitorHasChosen     = true

				return
			}

			wpm.ucUiProcessConsent()
		}

		/**
		 * OneTrust
		 *
		 * https://www.onetrust.com/
		 */

		if (cookie = wpm.getCookie("OptanonConsent")) {

			// console.log("OneTrust settings detected")

			let params = new URLSearchParams(cookie)
			let groups = params.get("groups").split(",")

			// Groups is an array like this ['1:1', '2:0', '3:1', '4:1']. Make it an object with key value pairs
			let groupsObject = {}
			groups.forEach((group) => {

				let groupArray              = group.split(":")
				groupsObject[groupArray[0]] = groupArray[1]
			})

			// group mapping
			// 1 = necessary
			// 2 = analytics
			// 3 = functional
			// 4 = ads

			wpmConsentValues.categories.analytics = groupsObject["2"] === "1"
			wpmConsentValues.categories.ads       = groupsObject["4"] === "1"
			wpmConsentValues.visitorHasChosen     = true

			return
		}

		/**
		 * Termly
		 *
		 * https://www.termly.io/
		 */

		if (cookie = getTermlyCookies()) {
			wpmConsentValues.categories.analytics = cookie.analytics === true
			wpmConsentValues.categories.ads       = cookie.ads === true
			wpmConsentValues.visitorHasChosen     = cookie.visitorHasChosen === true
			return
		}

		/**
		 * Real Cookie Banner
		 *
		 * https://devowl.io/wordpress-real-cookie-banner/
		 */
		if (wpm.doesACookieForTheRealCookieBannerExist()) {

			// console.log("Real Cookie Banner settings detected")

			wpmConsentValues.mode             = "pixel"
			wpmConsentValues.visitorHasChosen = true

			wpm.waitForLibrary("consentApi")

			// console.log("Real Cookie Banner consentApi loaded")

			if (!window.consentApi) {
				console.error("Pixel Manager for WooCommerce: window.consentApi is not available")
				return
			}

			const settings = [
				{
					"service": "adroll-ads",
					"type"   : "marketing",
					"cookies": ["__adroll_fpc", "_ar_v4", "_adroll"],
				},
				{
					"service": "bing-ads",
					"type"   : "marketing",
					"cookies": ["_uetsid", "_uetvid"],
				},
				{
					"service": "facebook-ads",
					"type"   : "marketing",
					"cookies": ["_fbp"],
				},
				{
					"service": "google-analytics",
					"type"   : "statistics",
					"cookies": ["_ga", "_gid", "_gat", "_gat_gtag_UA_*"],
				},
				{
					"service": "google-optimize",
					"type"   : "statistics",
					"cookies": ["_ga", "_gid", "_gat", "_gat_gtag_UA_*"],
				},
				{
					"service": "google-ads",
					"type"   : "marketing",
					"cookies": ["_gcl_au", "_gcl_aw", "_gcl_dc", "_gac_*"],
				},
				{
					"service": "hotjar",
					"type"   : "statistics",
					"cookies": ["_hj*", "_hjid"],
				},
				{
					"service": "linkedin-ads",
					"type"   : "marketing",
					"cookies": ["_li_ss", "_li_id", "_li_mk_*"],
				},
				{
					"service": "microsoft-ads",
					"type"   : "marketing",
					"cookies": ["_uetsid", "_uetvid"],
				},
				{
					"service": "outbrain-ads",
					"type"   : "marketing",
					"cookies": [],
				},
				{
					"service": "pinterest-ads",
					"type"   : "marketing",
					"cookies": ["_pinterest_ct_ua", "_pinterest_ct_rt", "_pin_unauth", "_derived_epik", "_pinterest_sess"],
				},
				{
					"service": "reddit-ads",
					"type"   : "marketing",
					"cookies": ["_rdt_uuid"],
				},
				{
					"service": "snapchat-ads",
					"type"   : "marketing",
					"cookies": ["sc_at", "sc_anonymous_id", "sc_id", "_scid", "_scid_r"],
				},
				{
					"service": "taboola-ads",
					"type"   : "marketing",
					"cookies": [],
				},
				{
					"service": "tiktok-ads",
					"type"   : "marketing",
					"cookies": ["_ttp", "_ttclid", "ttwid"],
				},
				{
					"service": "twitter-ads",
					"type"   : "marketing",
					"cookies": ["twitter_ads_id", "twid", "_twclid", "muc_ads"],
				},
			]


			// For each service in settings
			// and each cookie in service.cookies
			// check if consentApi.consentSync("http", cookie, "*") returns an object that contains a not empty object called "cookie"
			// If one of the cookies returns true, then push service.service to wpmConsentValues.pixels
			settings.forEach((service) => {

				service.cookies.forEach((cookie) => {

					let consent = window.consentApi.consentSync("http", cookie, "*")

					if (consent?.cookie && consent?.cookieOptIn) {

						wpmConsentValues.pixels.push(service.service)

						if (service.type === "statistics") {
							wpmConsentValues.categories.analytics = true
						}

						if (service.type === "marketing") {
							wpmConsentValues.categories.ads = true
						}

						// continue with the next service
						return
					}
				})
			})

			return
		}
	}

	wpm.doesACookieForTheRealCookieBannerExist = () => {

		// Find a cookie that has a prefix of real_cookie_banner
		// If one is found, then return true
		// If none is found, then return false

		let cookies = document.cookie.split(";")

		for (let i = 0; i < cookies.length; i++) {
			let cookie = cookies[i].trim()

			if (cookie.startsWith("real_cookie_banner")) {
				return true
			}
		}

		return false
	}

	// Only run after having made sure that the UC_UI library is loaded
	wpm.ucUiProcessConsent = () => {

		if (typeof UC_UI === "undefined") return

		if (UC_UI.areAllConsentsAccepted()) {
			pmw.consentAcceptAll()
		}

		const ucStatisticsSlug = UC_UI.getSettingsLabels().categories.filter(data => data.label === "Statistics")[0].slug

		pmw.consentAdjustSelectively(
			{
				analytics: !UC_UI.getServicesBaseInfo().filter(data => data.categorySlug === ucStatisticsSlug && data.consent.status === false).length > 0,
				ads      : !UC_UI.getServicesBaseInfo().filter(data => data.categorySlug === "marketing" && data.consent.status === false).length > 0,
			},
		)
	}

	wpm.updateConsentCookieValues()

	wpm.setConsentDefaultValuesToExplicit = () => {
		wpmConsentValues.categories = {
			analytics: false,
			ads      : false,
		}
	}

	wpm.canIFire = (category, pixelName) => {

		let canIFireMode

		if ("category" === wpmConsentValues.mode) {
			canIFireMode = !!wpmConsentValues.categories[category]
		} else if ("pixel" === wpmConsentValues.mode) {
			canIFireMode = wpmConsentValues.pixels.includes(pixelName)

			// If a user sets "bing-ads" in Borlabs Cookie instead of
			// "microsoft-ads" in the Borlabs settings, we need to check
			// for that too.
			if (false === canIFireMode && "microsoft-ads" === pixelName) {
				canIFireMode = wpmConsentValues.pixels.includes("bing-ads")
			}
		} else {
			console.error("Couldn't find a valid consent mode in wpmConsentValues")
			canIFireMode = false
		}

		if (canIFireMode) {
			return true
		} else {
			if (true || wpm.urlHasParameter("debugConsentMode")) {
				wpm.logPreventedPixelLoading(pixelName, category)
			}

			return false
		}
	}

	wpm.logPreventedPixelLoading = (pixelName, category) => {

		if (wpmDataLayer?.shop?.cookie_consent_mgmt?.explicit_consent) {
			console.log("Pixel Manager for WooCommerce: The \"" + pixelName + " (category: " + category + ")\" pixel has not fired because you have not given consent for it yet. (PMW is in explicit consent mode.)")
		} else {
			console.log("Pixel Manager for WooCommerce: The \"" + pixelName + " (category: " + category + ")\" pixel has not fired because you have removed consent for this pixel. (PMW is in implicit consent mode.)")
		}
	}

	/**
	 * Runs through each script in <head> and blocks / unblocks it according to the plugin settings
	 * and user consent.
	 */

	// https://stackoverflow.com/q/65453565/4688612
	wpm.scriptTagObserver = new MutationObserver((mutations) => {
		mutations.forEach(({addedNodes}) => {
			[...addedNodes]
				.forEach(node => {

					if ($(node).data("wpm-cookie-category")) {

						// If the pixel category has been approved > unblock
						// If the pixel belongs to more than one category, then unblock if one of the categories has been approved
						// If no category has been approved, but the Google Consent Mode is active, then only unblock the Google scripts

						if (wpm.shouldScriptBeActive(node)) {
							wpm.unblockScript(node)
						} else {
							wpm.blockScript(node)
						}
					}
				})
		})
	})

	wpm.scriptTagObserver.observe(document.head, {childList: true, subtree: true})
	// jQuery(document).on("DOMContentLoaded", () => wpm.scriptTagObserver.disconnect())
	document.addEventListener("DOMContentLoaded", () => wpm.scriptTagObserver.disconnect())

	wpm.shouldScriptBeActive = node => {

		if (
			wpmDataLayer.shop.cookie_consent_mgmt.explicit_consent ||
			wpmConsentValues.visitorHasChosen
		) {

			if (wpmConsentValues.mode === "category" && $(node).data("wpm-cookie-category").split(",").some(element => wpmConsentValues.categories[element])) {
				return true
			} else if (wpmConsentValues.mode === "pixel" && wpmConsentValues.pixels.includes($(node).data("wpm-pixel-name"))) {
				return true
			} else if (wpmConsentValues.mode === "pixel" && $(node).data("wpm-pixel-name") === "google" && ["google-analytics", "google-ads"].some(element => wpmConsentValues.pixels.includes(element))) {
				return true
			} else if (wpmDataLayer?.pixels?.google?.consent_mode?.active && $(node).data("wpm-pixel-name") === "google") {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}


	wpm.unblockScript = (scriptNode, removeAttach = false) => {

		if (removeAttach) $(scriptNode).remove()

		let wpmSrc = $(scriptNode).data("wpm-src")
		if (wpmSrc) $(scriptNode).attr("src", wpmSrc)

		scriptNode.type = "text/javascript"

		if (removeAttach) $(scriptNode).appendTo("head")

		// jQuery(document).trigger("wpmPreLoadPixels")
		document.dispatchEvent(new Event("wpmPreLoadPixels"))
	}

	wpm.blockScript = (scriptNode, removeAttach = false) => {

		if (removeAttach) $(scriptNode).remove()

		if ($(scriptNode).attr("src")) $(scriptNode).removeAttr("src")
		scriptNode.type = "blocked/javascript"

		if (removeAttach) $(scriptNode).appendTo("head")
	}

	wpm.unblockAllScripts = (analytics = true, ads = true) => {
		// jQuery(document).trigger("wpmPreLoadPixels")
		wpm.setConsentValueCategories(analytics, ads)
		document.dispatchEvent(new Event("wpmPreLoadPixels"))
	}

	wpm.unblockSelectedPixels = () => {
		// jQuery(document).trigger("wpmPreLoadPixels")
		document.dispatchEvent(new Event("wpmPreLoadPixels"))
	}

	wpm.explicitConsentStateAlreadySet = () => {

		if (wpmConsentValues.explicitConsentStateAlreadySet) {
			return true
		} else {
			wpmConsentValues.explicitConsentStateAlreadySet = true
		}
	}

	wpm.updatePixelConsentForPixelTypeCMPs = () => {

		wpm.updateConsentCookieValues()

		if (wpmConsentValues.mode === "pixel") {

			wpm.unblockSelectedPixels()
			wpm.updateGoogleConsentMode(wpmConsentValues.pixels.includes("google-analytics"), wpmConsentValues.pixels.includes("google-ads"))
		} else {

			wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
			wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
		}
	}


	/**
	 * Block or unblock scripts for each CMP immediately after cookie consent has been updated
	 * by the visitor.
	 */

	/**
	 * Borlabs Cookie
	 * If the visitor accepts cookies in Borlabs Cookie unblock the scripts.
	 */
	document.addEventListener("borlabs-cookie-consent-saved", () => {
		wpm.updatePixelConsentForPixelTypeCMPs()
	})

	/**
	 * Real Cookie Banner
	 * If the visitor accepts cookies in Real Cookie Banner unblock the scripts.
	 */

	document.addEventListener("RCB/OptIn/All", () => {
		console.log("consent given or changed, check again with consentSync...")

		wpm.updatePixelConsentForPixelTypeCMPs()
	})


	/**
	 * Cookiebot
	 * 	If visitor accepts cookies in Cookiebot unblock the scripts
	 * 	https://www.cookiebot.com/en/developer/
	 */
	window.addEventListener("CookiebotOnAccept", () => {
		if (Cookiebot.consent.statistics) wpmConsentValues.categories.analytics = true
		if (Cookiebot.consent.marketing) wpmConsentValues.categories.ads = true

		wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
		wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)

	}, false)

	/**
	 * WP Consent API
	 * https://github.com/rlankhorst/wp-consent-level-api
	 * https://wordpress.org/plugins/wp-consent-api/
	 */

	document.addEventListener("wp_listen_for_consent_change", e => {

		const changedConsentCategory = e.detail

		for (const key in changedConsentCategory) {

			// console.log("consent given or changed, check again with consentSync...", key, changedConsentCategory[key])

			// if the key is marketing
			if (key === "marketing") {
				wpmConsentValues.categories.ads = changedConsentCategory[key] === "allow"
			}

			// if the key is statistics
			if (key === "statistics") {
				wpmConsentValues.categories.analytics = changedConsentCategory[key] === "allow"
			}

		}

		// console log the consent values
		// console.log("WP Consent API settings detected: ", wpmConsentValues)

		wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
		wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
	})


	/**
	 * Cookie Script
	 * If visitor accepts cookies in Cookie Script unblock the scripts
	 * https://support.cookie-script.com/article/20-custom-events
	 */
	// jQuery(document).on("CookieScriptAccept", e => {
	document.addEventListener("CookieScriptAccept", e => {

		if (e.detail.categories.includes("performance")) wpmConsentValues.categories.analytics = true
		if (e.detail.categories.includes("targeting")) wpmConsentValues.categories.ads = true

		wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
		wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
	})

	/**
	 * Cookie Script
	 * If visitor accepts cookies in Cookie Script unblock the scripts
	 * https://support.cookie-script.com/
	 */
	// jQuery(document).on("CookieScriptAcceptAll", () => {
	document.addEventListener("CookieScriptAcceptAll", () => {

		wpm.setConsentValueCategories(true, true)
		wpm.unblockAllScripts(true, true)
		wpm.updateGoogleConsentMode(true, true)
	})

	/**
	 * Complianz Cookie
	 *
	 * If visitor accepts cookies in Complianz unblock the scripts
	 */

	wpm.cmplzStatusChange = (cmplzConsentData) => {

		if (cmplzConsentData.detail.categories.includes("statistics")) wpm.updateConsentCookieValues(true, null)
		if (cmplzConsentData.detail.categories.includes("marketing")) wpm.updateConsentCookieValues(null, true)

		wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
		wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
	}

	// jQuery(document).on("cmplzStatusChange", wpm.cmplzStatusChange)
	document.addEventListener("cmplzStatusChange", wpm.cmplzStatusChange)
	// jQuery(document).on("cmplz_status_change", wpm.cmplzStatusChange)
	document.addEventListener("cmplz_status_change", wpm.cmplzStatusChange)

	// Cookie Compliance by hu-manity.co (free and pro)
	// If visitor accepts cookies in Cookie Notice by hu-manity.co unblock the scripts (free version)
	// https://wordpress.org/support/topic/events-on-consent-change/#post-15202792
	// jQuery(document).on("setCookieNotice", () => {
	document.addEventListener("setCookieNotice", () => {
		wpm.updateConsentCookieValues()

		wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
		wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
	})

	/**
	 * Cookie Compliance by hu-manity.co (free and pro)
	 * If visitor accepts cookies in Cookie Notice by hu-manity.co unblock the scripts (pro version)
	 * https://wordpress.org/support/topic/events-on-consent-change/#post-15202792
	 * Because Cookie Notice has no documented API or event that is being triggered on consent save or update
	 * we have to solve this by using a mutation observer.
	 *
	 * @type {MutationObserver}
	 */

	wpm.huObserver = new MutationObserver(mutations => {
		mutations.forEach(({addedNodes}) => {
			[...addedNodes]
				.forEach(node => {

					if (node.id === "hu") {

						// jQuery(".hu-cookies-save").on("click", function () {
						// jQuery(".hu-cookies-save") in pure JavaScript
						document.querySelector(".hu-cookies-save").addEventListener("click", () => {
							wpm.updateConsentCookieValues()
							wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
							wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
						})
					}
				})
		})
	})

	if (window.hu) {
		wpm.huObserver.observe(document.documentElement || document.body, {childList: true, subtree: true})
	}

	/**
	 * Iubenda CMP
	 *
	 * https://www.iubenda.com/en/help/1205-how-to-configure-your-cookie-solution-advanced-guide
	 *
	 * If visitor accepts cookies in Iubenda CMP unblock the scripts
	 *
	 * This might not work as of now (11.07.2023) the Iubenda CMP does not trigger any events.
	 *
	 * We might have to use a mutation observer to detect changes in the DOM.
	 * This is also risky because we might read out the values faster than Iubenda can update them.
	 */

	// Load after the document is fully loaded
	// Check if a button with one of the following classes was clicked
	// iubenda-cs-btn-primary
	// If it was clicked, recheck the cookies and unblock the scripts
	// jQuery(document).ready(() => {
	//
	// 	jQuery(document).on("click", ".iubenda-cs-btn-primary", () => {
	// 		wpm.updateConsentCookieValues()
	// 		wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
	// 		wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
	// 	})
	//
	// 	// if the event save-button-clicked is triggered, recheck the cookies and unblock the scripts
	// 	jQuery(document).on("iubenda-cs-btn-primary", () => {
	// 		// wpm.updateConsentCookieValues()
	// 		// wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
	// 		// wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
	// 		console.log("iubenda-cs-btn-primary clicked")
	// 	})
	//
	// })
	//
	// wpm.iubObserver = new MutationObserver(mutations => {
	// 	mutations.forEach(({addedNodes}) => {
	// 		[...addedNodes]
	// 			.forEach(node => {
	//
	// 				// console.log(node)
	//
	// 				if (node.id === "iubenda-cs-banner") {
	//
	// 					// jQuery(".hu-cookies-save").on("click", function () {
	// 					// jQuery(".hu-cookies-save") in pure JavaScript
	// 					document.querySelector(".iubenda-cs-btn-primary").addEventListener("click", () => {
	// 						// wpm.updateConsentCookieValues()
	// 						// wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
	// 						// wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
	//
	// 						console.log("iubenda-cs-btn-primary clicked")
	// 					})
	// 				}
	// 			})
	// 	})
	// })
	//
	// if (window._iub) {
	// 	wpm.iubObserver.observe(document.documentElement || document.body, {childList: true, subtree: true})
	// }


	/**
	 * Usercentrics Event Listeners
	 *
	 * https://docs.usercentrics.com/#/v2-events?id=usage-as-window-event
	 */

	window.addEventListener("ucEvent", e => {
		if (e.detail && e.detail.event == "consent_status") {
			// check for consent status of service "Google Ads Remarketing"
			if (e.detail["Google Ads Remarketing"] === true) {
				console.log("Google Ads Remarketing has consent")
			} else {
				console.log("Google Ads Remarketing has no consent")
			}
		}
	})

	// https://docs.usercentrics.com/#/v2-events?id=uc_ui_cmp_event
	window.addEventListener("UC_UI_CMP_EVENT", event => {

		if (event.detail.type === "ACCEPT_ALL") {
			// console.log('accept all');

			pmw.consentAcceptAll()
		}

		if (event.detail.type === "DENY_ALL") {
			pmw.consentRevokeAll()
		}

		if (event.detail.type === "SAVE") {
			console.log("event.detail", event.detail)
		}
	})


	/**
	 * OneTrust Event Listeners
	 *
	 * CookiePro by OneTrust doesn't emit any events when the user accepts or declines cookies.
	 */

	// There are two accept all buttons. One in the first banner and one in the settings window. Both have different identifiers.
	jQuery("#accept-recommended-btn-handler, #onetrust-accept-btn-handler").on("click", () => {

		// If OneTrust is not loaded, return
		if (typeof window.OneTrust === "undefined") return

		pmw.consentAcceptAll()
	})

	// There are two revoke all buttons. One in the first banner and one in the settings window. Both have different identifiers.
	jQuery(".ot-pc-refuse-all-handler, #onetrust-reject-all-handler").on("click", () => {
		pmw.consentRevokeAll()
	})

	// There is one save button that saves mixed consent. It is in the settings window. We reload the page after saving to reflect the changes.
	jQuery(".save-preference-btn-handler.onetrust-close-btn-handler").on("click", () => {
		location.reload()

		// OneTrust.OnConsentChanged(function (e) {
		// 	pmw.consentAdjustSelectively({
		// 		analytics: e.detail.includes("2"),
		// 		ads      : e.detail.includes("4"),
		// 	})
		// })
	})

	/**
	 * Termly Event Listeners
	 *
	 * The event listener is within /sources/termly_web/resource-blocker/src/constants/lifeCycle.js
	 */

	document.addEventListener("termlyConsent", function (e) {

		const consent = e.detail

		if (consent.advertising && consent.analytics) {
			pmw.consentAcceptAll()
		} else if (!consent.advertising && !consent.analytics) {
			pmw.consentRevokeAll()
		} else {
			pmw.consentAdjustSelectively({
				analytics: consent.analytics,
				ads      : consent.advertising,
			})
		}
	})

	wpm.updateGoogleConsentMode = (analytics = true, ads = true) => {

		try {
			if (
				!window.gtag ||
				!wpmDataLayer.shop.cookie_consent_mgmt.explicit_consent
			) return

			gtag("consent", "update", {
				analytics_storage: analytics ? "granted" : "denied",
				ad_storage       : ads ? "granted" : "denied",
			})
		} catch (e) {
			console.error(e)
		}
	}

}(window.wpm = window.wpm || {}, jQuery));


(function (pmw, $, undefined) {

	/**
	 * Pixel Manager Cookie Consent API
	 */

	// Accept consent for all cookies
	pmw.consentAcceptAll = (settings = {}) => {

		settings.duration = settings.duration || 365

		pmw.consentSetCookie(true, true, settings.duration)
		wpm.unblockAllScripts(true, true)
		wpm.updateGoogleConsentMode(true, true)
	}

	// Accept consent selectively
	pmw.consentAdjustSelectively = (settings) => {

		// if settings.marketing is set
		// write its value to settings.ads
		// and delete settings.marketing
		if (settings.marketing !== undefined) {
			settings.ads = settings.marketing
			delete settings.marketing
		}

		// If settings.analytics is set, keep it, otherwise set it to wpm.getConsentValues().categories.analytics
		settings.analytics = settings.analytics !== undefined ? settings.analytics : wpm.getConsentValues().categories.analytics
		settings.ads       = settings.ads !== undefined ? settings.ads : wpm.getConsentValues().categories.ads
		settings.duration  = settings.duration || 365

		// console.log("settings", settings)

		pmw.consentSetCookie(settings.analytics, settings.ads, settings.duration)
		wpm.unblockAllScripts(settings.analytics, settings.ads)
		wpm.updateGoogleConsentMode(settings.analytics, settings.ads)
	}

	// Remove consent for all cookies
	pmw.consentRevokeAll = (settings = {}) => {

		settings.duration = settings.duration || 365

		wpm.setConsentValueCategories(false, false)
		pmw.consentSetCookie(false, false, settings.duration)
		wpm.updateGoogleConsentMode(false, false)
	}

	// Set a cookie called pmw_cookie_consent with the value of pmw_cookie_consent
	// and set the default expiration date to 1 year from now
	pmw.consentSetCookie = (analytics, ads, duration = 365) => {
		wpm.setCookie("pmw_cookie_consent", JSON.stringify({analytics, ads}), duration)
	}

	pmw.triggerPmwCookieConsentManagementLoaded = () => {
		// Trigger an event once the PMW consent management has been loaded
		document.dispatchEvent(new Event("pmw_cookie_consent_management_loaded"))
		document.dispatchEvent(new Event("pmwCookieConsentManagementLoaded"))
	}

	pmw.triggerPmwCookieConsentManagementLoaded()

	// Get that state for the statistics or marketing cookies
	pmw.getConsentValues = () => {
		return wpm.getConsentValues()
	}

}(window.pmw = window.pmw || {}, jQuery))
