/**
 * Copyright 2023 SweetCode. All rights reserved.
 *
 * Load LinkedIn premium functions
 * */

(function (wpm, $, undefined) {

	wpm.load_linkedin_pixel = () => {

		try {
			wpmDataLayer.pixels.linkedin.loaded = true

			// @formatter:off

			_linkedin_partner_id = wpmDataLayer.pixels.linkedin.partner_id;
			window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
			window._linkedin_data_partner_ids.push(_linkedin_partner_id);

			(function(l) {
			if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
			window.lintrk.q=[]}
			var s = document.getElementsByTagName("script")[0];
			var b = document.createElement("script");
			b.type = "text/javascript";b.async = true;
			b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
			s.parentNode.insertBefore(b, s);})(window.lintrk);

			// @formatter:on

		} catch (e) {
			console.error(e)
		}
	}

}(window.wpm = window.wpm || {}, jQuery));
