/**
 * Copyright 2023 SweetCode. All rights reserved.
 *
 * Source: https://www.outbrain.com/help/advertisers/dynamic-values/
 *
 * All event listeners
 * */

// Pixel load event listener
jQuery(document).on("wpmLoadPixels", () => {

	if (wpmDataLayer?.pixels?.outbrain?.advertiser_id && !wpmDataLayer?.pixels?.outbrain?.loaded) {
		if (wpm.canIFire("ads", "outbrain-ads")) wpm.loadOutbrainPixel()
	}
})

// view search event
jQuery(document).on("pmwEvent:Search", () => {

	try {
		if (!wpmDataLayer?.pixels?.outbrain?.loaded) return

		obApi("track", wpmDataLayer.pixels.outbrain.event_names.search)

	} catch (e) {
		console.error(e)
	}
})

// ViewContent event
jQuery(document).on("wpmViewItem", (event, product = null) => {

	try {
		if (!wpmDataLayer?.pixels?.outbrain?.loaded) return

		obApi("track", wpmDataLayer.pixels.outbrain.event_names.view_content)
	} catch (e) {
		console.error(e)
	}
})

// AddToCart event
jQuery(document).on("wpmAddToCart", (event, product) => {

	try {
		if (!wpmDataLayer?.pixels?.outbrain?.loaded) return

		obApi("track", wpmDataLayer.pixels.outbrain.event_names.add_to_cart)
	} catch (e) {
		console.error(e)
	}
})

// begin_checkout event
jQuery(document).on("wpmBeginCheckout", () => {

	try {
		if (!wpmDataLayer?.pixels?.outbrain?.loaded) return

		obApi("track", wpmDataLayer.pixels.outbrain.event_names.start_checkout)
	} catch (e) {
		console.error(e)
	}
})

// view order received page event
// https://www.outbrain.com/help/advertisers/dynamic-values/
jQuery(document).on("wpmOrderReceivedPage", () => {

	try {
		if (!wpmDataLayer?.pixels?.outbrain?.loaded) return

		obApi("track", wpmDataLayer.pixels.outbrain.event_names.purchase, {
			orderValue: String(wpmDataLayer.order.value_filtered),
			currency  : wpmDataLayer.order.currency,
			orderId   : String(wpmDataLayer.order.id),
		})

	} catch (e) {
		console.error(e)
	}
})
